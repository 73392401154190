import React, { useCallback } from 'react';

import type { Trip } from '../../model/Trip';
import type { Driver, Owner } from '../../model/User';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import Link from '../Link';

import Avatar from './Avatar';
import StarRating from './StarRating';

type Props = {
  user: Driver | Owner;
  trip: Trip;
  hidePhone?: boolean;
  onClick?: () => void;
};

const UserLocket = ({ user, trip, hidePhone, onClick }: Props) => {
  const { navigate, generatePath } = useRouteContext();

  const redirect = useCallback(() => {
    onClick?.();
    navigate(generatePath(RouteEnum.PUBLIC_PROFILE, { hash: user.hash }));
  }, [user, onClick]);

  return (
    <div aria-labelledby="locket-id-012" className="locket" data-locket-size="xs">
      <figure className="locket__figure" onClick={redirect} style={{ cursor: 'pointer' }}>
        <Avatar image={user.avatar_document} />
      </figure>
      <div className="locket__main">
        <div className="locket__header">
          <div className="locket__header--main">
            <h3 className="locket--name" id="locket-id-012" onClick={redirect} style={{ cursor: 'pointer' }}>
              {user.first_name} {user.last_name.substring(0, 1)}.
            </h3>
            <div aria-label="Hodnocení 3/5" className="rating d-inline-flex" data-rating-size="xs">
              <StarRating rating={user.average_rating} />
            </div>
          </div>
          <div className="locket__header--action">
            {!hidePhone && user.phone ? (
              <a aria-label="Telefon" className="round-badge" data-badge-color="primary" href={`tel:${user.phone}`}>
                <i className="icon icon-phone" />
              </a>
            ) : null}
            <Link
              aria-label="Message"
              className="round-badge"
              data-badge-color="primary"
              params={{ chat: trip.chat_id }}
              to={RouteEnum.MESSAGES}
            >
              <i className="icon icon-message" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLocket;
